@keyframes surge {
    0% {
      left: -200%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 200%;
    }
  }
  
  .animate-surge {
    position: absolute;
    left: -100%;
    width: 200%;
    background: linear-gradient(90deg, transparent 0%, white 50%, transparent 100%);
    animation: surge 2s infinite ease-in-out;
  }
  

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); /* Optional: slight upward movement */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in {
    animation: fadeIn 0.7s forwards; /* Duration of the fade-in */
  }

  html {
    scroll-behavior: smooth;
  }